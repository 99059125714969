.header {
    height: 200px;
    text-align: center;
    padding-top: 64px;
    margin: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.header h1 {
    font-size: 60px;
    font-weight: bold;
}

.header h2 {
    font-size: 24px;
    font-weight: normal;
    max-width: 900px;
}

@media (max-width:690px) {
    .header h1 {
        font-size: 48px;
    }
}

@media (max-width:515px) {
    .overlay {
        height: calc(100vh - 320px);
    }

    .header {
        margin: 0 24px;
        padding-top: 0px;
    }

    .header h1 {
        font-size: 36px;
    }

    .header h2 {
        font-size: 20px;
        margin-top: 42px;
        font-weight: normal;
    }
}