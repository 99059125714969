.background {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.overlay {
    height: calc(100vh - 350px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.loading {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.loading::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: black;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

.input-field {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 400px;

}

@media (max-width:480px) {
    .input-field {
        padding: 10px;
        border-radius: 5px;
        border: none;
        width: 250px;
    }

    .loading::after {
        left: 42%;
    }
    
    
}

.button-search {
    margin-left: 10px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    border: none;
}