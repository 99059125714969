.market-container {
    margin-top: 52px;
    text-align: center;
    font-size: 24px;
}

.market-container  span {
    margin-top: 52px;
    text-align: center;
    font-size: 24px;
}
.market-list{
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
}

.market-list img {
    height: 44px;
    width: auto;
    margin: 20px;
    border-radius: 8px;
    cursor: pointer;
}

.market-list img:hover {
    cursor: pointer;
}

.market-selected {
    -webkit-filter: grayscale(1) invert(0);
    filter: grayscale(1) invert(0);
}
