.info-ico {
  padding: 20px;
  flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

.info-ico-right {
  text-align: right;
  width: 90%;
}

.info-ico-left{
  text-align: left;
  width: 10%;
}

@media (max-width:480px) {
  .info-ico {
    margin-bottom: 40px;
  }
}


.info-ico img {
  height: 36px;
  width: auto;
  cursor: pointer;
}

.info-ico svg {
  height: 36px;
  width: auto;
  cursor: pointer;
  margin-right: 24px;
}

.modal-about {
  font-size: 14px;
  background-color: white;
  border-color: black;
  padding: 20px;
  border-width: thin;
  border-radius: 16px 0 16px 0;
  border-style: solid;
  width: 500px;
}

@media (max-width:600px) {
  .modal-about {
    width: auto;
    margin: 20px;
  }

  .modal-about>.content-cookies {
    overflow-y: scroll;
    height: 300px;
    overflow-x: scroll;
  }

  .modal-about>.close-about {
    margin: 20px;
  }

}

.cookies-table {
  margin-top: 20px;
}

.modal-about>.header-about {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.modal-about>.content-about {
  width: 100%;
  padding: 10px 5px;
}

.modal-about>.content-cookies {
  overflow-y: scroll;
  height: 300px;
  overflow-x: hidden;
}

.modal-about>.close-about {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.github-ico{
  height: 90px;
  width: auto;
}